import React from "react"
import { AiFillFacebook, AiOutlineInstagram } from "react-icons/ai"
import { SiGooglemybusiness } from "react-icons/si"
const ITServices = [
  {
    icon: <SiGooglemybusiness />,
    title: "Googleマイビジネス",
    description:
      "Googleマイビジネスの設定・GoogleMAP検索にお店の情報がでるように",
  },
  {
    icon: <AiFillFacebook />,
    title: "Facebookページ",
    description: "ビジネス用のFacebookページの設定・Instagram連携などの設定",
  },
  {
    icon: <AiOutlineInstagram />,
    title: "Instagram活用",
    description: "Instagram活用サポート",
  },
]

export default ITServices
