import React from "react"
import { Parallax } from "react-parallax"
import bgImage from "../../assets/images/IT-support.jpg"
import { Link } from "gatsby"
import ITServices from "../../constants/IT-service"
import Services from "../../components/Services"
import Seo from "../../components/utilities/Seo"

const ITSupport = () => {
  return (
    <section>
      <Seo title="IT活用支援" />
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={400}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>IT活用支援サービス</h1>

            <h2>便利な各種サービスの支援いたします</h2>
            <p>IT・DX化などのIT関連サービス利用の支援いたします。</p>
            <div>
              <Link to="/contact" className="btn-primary">
                お問合せ
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
      <Services servicesList={ITServices} />
    </section>
  )
}

export default ITSupport
